<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    style="width:100%;color:#858585;"
    @selection-change="selectRow"
    :header-cell-style="tableHeaderCellStyle">
    <el-table-column
     type="selection"
     fill="#00CCCC"
     fixed="left">
    </el-table-column>
    <!-- 表头开始 -->
    <el-table-column
      prop="name"
      label="就诊人姓名"
      align="center">
    </el-table-column>
    <el-table-column
      prop="gender"
      label="性别"
      align="center">
    </el-table-column>
    <el-table-column
      prop="age"
      label="年龄"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="cardId"
      label="证件号码"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="mobile"
      label="手机号"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
        prop="workTime"
        align="center"
        label="预约日期"
        show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      align="center"
      label="预约时间段">
       <template slot-scope="scope">
        <span>{{scope.row.workTimeStart.substring(10,16)}} -- {{scope.row.workTimeEnd.substring(10,16)}}</span>
      </template>
    </el-table-column>
     <el-table-column
      align="center"
      label="预约状态">
       <template slot-scope="scope">
       <span style="display: inline-block;background-color: #CEF8F8;border-radius: 15px;width: 86px;color:#00CCCC" v-if="scope.row.status=='已预约'">{{scope.row.status}}</span>
       <span style="display: inline-block;background-color: #FFC2C2;border-radius: 15px;width: 86px;color:#FC5555" v-if="scope.row.status!='已预约'">{{scope.row.status}}</span>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      label="操作"
      width="120">
      <template slot-scope="scope">
        <span style="color:#00cccc;cursor: pointer;" @click="clickRow(scope.row)">查看详情</span>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <div class="page">
  <!-- left -->
  <span class="pageLeft">共 {{count}} 条记录 第 {{currentPage}} / {{tatalPage}}页</span>
  <!-- right -->
  <el-pagination
      @size-change="SizeChange"
      background
      @current-change="CurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="onlyPage"
      :pager-count="5"
      layout="sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
  </div>
</div>
</template>

<script>
import {getRecoverList} from '@/api/order.js'

  export default {
    data() {
      return {
        tableData: [],//表格数据
        multipleSelection:[],// tableCheckboxValue
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:10,// 当前页面可以展示多少条数据,默认10 
      }
    },
  
     mounted(){
       // this.getData(1,10,this.$parent.roomid,null,null); 
     },
    //监听勾选值
    watch:{
        multipleSelection:{
            handler(pre){
              var tem = pre;
              if(tem.length==0){
                this.$parent.isSelect = true;
              }
              if(tem.length>0){
                this.$parent.isSelect = false;
                var temp = [];
                tem.forEach((a,b)=>{
                  if(a.status!="已预约"){
                    this.$parent.isSelect = true;
                    this.$message({
                      message: '只有已预约状态可选',
                      type: 'warning',
                      duration:1000
                    });            
                  }else{
                    this.$parent.isSelect = false;
                    temp.push(a.id)
                  }
                });
                this.$parent.tableId = temp;
               
               }
            },
            deep:true,
            immediate:true
        }
    },

    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
        },
      // getData
      getData(currentPage,onlyPage,wid,status,dateSeach){ 
              const rLoading = this.openLoading();// 开始loading
              getRecoverList({
              currentPage: currentPage,
              pageSize: onlyPage,
              wid:wid,
              date:dateSeach,
              status:status
            }).then((res)=>{
              if(res.data.status == 200){
                this.tableData = res.data.data.records;
                this.tatalPage =parseInt(res.data.data.size) ;
                this.count =parseInt(res.data.data.total);
                rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },
      // 复选框选项
      selectRow(val) {
       this.multipleSelection = val;
      },
      // 分页相关
      SizeChange(val){
        this.onlyPage = val;
        this.getData(this.currentPage,this.onlyPage,this.$parent.roomid,this.$parent.submitOption,this.$parent.seachDate);
      },
      CurrentChange(val){
        this.currentPage = val;
        this.getData(this.currentPage,this.onlyPage,this.$parent.roomid,this.$parent.submitOption,this.$parent.seachDate);
      },
      prevClick(val){
        // 上一页
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.$parent.roomid,this.$parent.submitOption,this.$parent.seachDate);
      },
      nextClick(val){
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.$parent.roomid,this.$parent.submitOption,this.$parent.seachDate);
      },
      //单击表格行
      clickRow(e){
       // console.log("ee",e)
        this.$router.push({
                name:'recoverDetails',
                query:{
                   id:e.patientId,
                   deptName:e.deptName,
                   workTime:e.workTime,
                   status:e.status,
                   timeRange:e.workTimeStart.substring(10,16)+" " + "--" +" "+e.workTimeEnd.substring(10,16),
                   hospital:this.$parent.hospital
                }
          })
      }
    },
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
  margin: 0 !important;
}
.resoult{
  text-align: left;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>